<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card v-if="!sidebarLoading" class="rounded-10 elevation-0 mt-2">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-list dense>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3"> Meter ID: </span>
                    <div
                      contenteditable
                      @blur="handleUpdateDeviceMeterNumber"
                      ref="editableMeterNumber"
                      class="editable-content"
                    >
                      {{ meterNumber }}
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3"> Reader ID: </span>
                    {{ deviceName }}
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-list dense>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3"> Name: </span>
                    <div
                      contenteditable
                      @blur="handleUpdateDeviceHouseNumber"
                      ref="editableDeviceHouseNumber"
                      class="editable-content"
                    >
                      {{ houseNumber }}
                    </div>
                  </v-list-item>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3"> Description: </span>
                    <div
                      contenteditable
                      @blur="handleUpdateDeviceDescription"
                      ref="editableDeviceDescripion"
                      class="editable-content"
                    >
                      {{ description }}
                    </div>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-list dense>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3">Status:</span>
                    <v-chip
                      :class="`item-${deviceStatus}`"
                      :color="getColor(deviceStatus)"
                      class="px-5 py-4"
                      elevation="0"
                      label
                      style="border-radius: 8px"
                    >
                      <span v-if="deviceStatus === 1">Reading</span>
                      <span v-if="deviceStatus === 3">Offline</span>
                      <span v-if="deviceStatus === 2">Sleep</span>
                    </v-chip>
                  </v-list-item>
                  <v-list-item>
                    <span class="font-weight-bold body-2 me-3"> Signal Strength: </span>
                    <v-icon class="mdi mdi-signal me-2" small></v-icon>
                    {{ signal }}
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-if="canMigrateDevice">
              <v-col cols="2">
                <Menu
                  name="Move Reader"
                  :menu-items="getDeviceMigrationOptions($route.query.productKey)"
                  @menu-click="onMenuItemClick"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="rounded-10 elevation-0">
          <v-card-text class="pa-0">
            <v-tabs v-model="tab" class="rounded-10">
              <v-tab
                v-for="item in items"
                :key="item"
                class="font-weight-bold text-capitalize"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="rounded-10">
              <v-tab-item v-for="item in items" :key="item">
                <v-card v-if="item === 'Read Data'" flat>
                  <v-card-text class="px-0">
                    <v-row class="px-4">
                      <v-col cols="12" sm="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateRangeText"
                              append-icon="mdi-calendar"
                              dense
                              hide-details
                              label="Start Date    —    End date"
                              outlined
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title range scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="menu = false">
                              Cancel
                            </v-btn>
                            <v-btn color="primary" text @click="saveHistory(date)">
                              OK
                            </v-btn>
                            <v-btn
                              v-if="date.length > 0"
                              text
                              color="primary"
                              @click="$refs.menu[0].save([])"
                              >Clear
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col class="text-end" cols="12" sm="6">
                        <ExportDialog
                          v-if="!getHistoryLoading"
                          :data="deviceHistory"
                          :itemData="itemData()"
                          :title="dateRangeText"
                          innerPage
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0" cols="12">
                        <v-data-table
                          ref="dataTable"
                          v-model="selected"
                          :footer-props="{
                            'items-per-page-options': [10, 25, 50, 100],
                          }"
                          :headers="headers"
                          :items="deviceHistory"
                          :items-per-page="25"
                          :loading="getHistoryLoading"
                          :options.sync="options"
                          :server-items-length="getTotalRecords"
                          checkbox-color="success"
                          class="elevation-0 bg-transparent"
                          item-key="lastTime"
                        >
                          <template v-slot:item.cloudIncrement="{ item }">
                            <p>{{ item.data.cloudIncrement }}</p>
                          </template>
                          <template v-slot:item.path="{ item }">
                            <v-tooltip
                              :nudge-top="-150"
                              allow-overflow
                              color="transparent"
                              top
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <img
                                  :src="getImageAbsUrl(item.path)"
                                  :style="`transform: rotate(${item.angle}deg)`"
                                  alt=""
                                  height="120"
                                  style="object-fit: contain"
                                  v-bind="attrs"
                                  width="50"
                                  v-on="on"
                                />
                              </template>
                              <div
                                :style="`transform: rotate(${item.angle}deg)`"
                                style="padding: 10px"
                              >
                                <img
                                  :src="getImageAbsUrl(item.path)"
                                  alt=""
                                  height="440"
                                  style="object-fit: contain"
                                  width="200"
                                />
                              </div>
                            </v-tooltip>
                          </template>
                          <template v-slot:item.data="{ item }">
                            <div class="py-2 d-flex">
                              <div
                                v-if="editableReading === item.request_id"
                                @input="updateItem(item, 'number', $event)"
                                contenteditable
                                @blur="stopEditing"
                                @keydown.enter.prevent="stopEditing"
                                @keydown.esc="cancelEditing"
                              >
                                {{ item.data.number }}
                              </div>
                              <div v-else @click="startEditing(item.request_id)">
                                {{ item.data.number }}
                              </div>
                            </div>
                          </template>
                          <template v-slot:item.createTime="{ item }">
                            <div class="py-2 text-left">
                              <p class="mb-0" style="white-space: nowrap">
                                Date:
                                <span class="body-2 font-weight-bold ms-1">
                                  {{ getDate(item.createTime) }}
                                </span>
                              </p>
                              <p class="mb-0">
                                Time:
                                <span class="body-2 font-weight-bold ms-1">
                                  {{ getTime(item.createTime) }}
                                </span>
                              </p>
                            </div>
                          </template>
                          <template v-slot:item.electricity="{ item }">{{
                            getPeriod(item)
                          }}</template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card v-if="item === 'Device Configuration'" class="px-10" flat>
                  <v-card-text class="px-0">
                    <v-form ref="deviceConfigurationFrom">
                      <!-- Wake-up Configuration -->
                      <v-list-item @click="toggleItem('wake-up-configuration')">
                        <v-list-item-content>
                          <v-list-item-title :class="{ active: activeItem === 'wake-up-configuration' }">Wake-up configuration</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon v-if="activeItem === 'wake-up-configuration'">
                          <v-icon>mdi-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item-content
                        v-show="activeItem === 'wake-up-configuration'"
                      >
                        <v-list-item-title>
                          <v-select
                            :items="wakeUpItems"
                            v-model="wakeUpInterval"
                            item-text="name"
                            item-value="value"
                            dense
                            label="Wake-up mode"
                            outlined
                          ></v-select>
                          <div v-if="wakeUpInterval === 2">
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  outlined
                                  dense
                                  type="number"
                                  :rules="intervalRule"
                                  label="Every"
                                  min="1"
                                  v-model="interval"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                  outlined
                                  dense
                                  :items="timeIntervals"
                                  item-text="name"
                                  item-value="value"
                                  v-model="selectedInterval"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </div>
                          <div v-if="wakeUpInterval === 1">
                            <v-row class="align-center">
                              <v-col cols="auto">
                                <span class="font-weight-bold">at</span>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  hide-details
                                  min="0"
                                  type="number"
                                  outlined
                                  dense
                                  v-model="hours"
                                  label="Hours"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="auto" class="text-center">
                                <div class="d-flex align-center">
                                  <span class="mx-2 font-weight-bold">:</span>
                                </div>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  hide-details
                                  min="0"
                                  type="number"
                                  outlined
                                  dense
                                  v-model="minutes"
                                  label="Minutes"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="auto">
                                <span class="font-weight-bold"> (AEDT) </span>
                              </v-col>
                            </v-row>
                          </div>
                          <div>
                            <v-row>
                              <v-col cols="12" md="12">
                                <v-switch
                                  v-model="needPic"
                                  label="Upload pictures or not"
                                  class="d-flex align-center mt-0"
                                  hide-details
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-switch
                                  v-model="rotate"
                                  label="Rotate 180°"
                                  class="d-flex align-center mt-0"
                                  hide-details
                                >
                                </v-switch>
                              </v-col>
                            </v-row>
                            <v-row class="align-center">
                                <v-col cols="4" class="text-right">
                                  <v-label for="color-channel">Color channel:</v-label>
                                </v-col>
                                <v-col cols="8">
                                  <v-select
                                    outlined
                                    dense
                                    :items="colorChannel"
                                    id="color-channel"
                                    item-text="name"
                                    item-value="value"
                                    v-model="selectedColorChannel"
                                    hide-details
                                  >
                                  </v-select>
                                </v-col>
                            </v-row>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>

                      <!-- Equipment details -->
                      <v-list-item @click="toggleItem('equipment-details')">
                        <v-list-item-content>
                          <v-list-item-title :class="{ active: activeItem === 'equipment-details' }">Equipment details</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon v-if="activeItem === 'equipment-details'">
                          <v-icon>mdi-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item-content v-show="activeItem === 'equipment-details'">
                        <v-list-item-title>
                          <!-- UUID serial number -->
                          <v-row>
                            <v-col cols="12" md="4" class="text-right">
                              <v-label>UUID serial number:</v-label>
                            </v-col>
                            <v-col cols="12" md="8"> {{ deviceName }} </v-col>
                          </v-row>
                          <!-- Model selection -->
                          <v-row class="align-center">
                            <v-col cols="12" md="4" class="text-right">
                              <v-label id="model-section">Model selection:</v-label>
                            </v-col>
                            <v-col cols="12" md="8">
                              <v-select
                                outlined
                                dense
                                :items="modelSection"
                                item-text="name"
                                item-value="value"
                                v-model="selectedModelSection"
                                id="model-section"
                                hide-details
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                          <!-- Picture quality -->
                          <v-row>
                            <v-col cols="12" md="4" class="text-right pt-5">
                              <v-label for="picture-quality">Picture quality:</v-label>
                            </v-col>
                            <v-col cols="12" md="8">
                              <v-text-field
                                outlined
                                dense
                                :rules="pictureQualityRule"
                                persistent-hint
                                type="number"
                                label="Picture quality"
                                id="picture-quality"
                                min="1"
                                v-model="picQuality"
                                required
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">mdi-information</v-icon>
                                    </template>
                                    <span
                                      >An integer of 1-5, the smaller the better the
                                      quality, the more traffic and other
                                      consumption.</span
                                    >
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <!-- Picture upload mode -->
                          <v-row class="align-center">
                            <v-col cols="12" md="4" class="text-right">
                              <v-label for="picture-upload-mode">Picture upload mode:</v-label>
                            </v-col>
                            <v-col cols="12" md="8">
                              <v-select
                                outlined
                                dense
                                :items="picMode"
                                item-text="name"
                                item-value="value"
                                v-model="selectedPicMode"
                                id="picture-upload-mode"
                                hide-details
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                          <!-- Flash brightness -->
                          <v-row>
                            <v-col cols="12" md="4" class="text-right pt-5">
                              <v-label for="flash-brightness">Flash brightness:</v-label>
                            </v-col>
                            <v-col cols="12" md="8">
                              <v-text-field
                                outlined
                                dense
                                persistent-hint
                                :rules="flashHeightRule"
                                type="number"
                                label="Flash brightness"
                                min="1"
                                id="flash-brightness"
                                v-model="flashHeight"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-icon v-on="on">mdi-information</v-icon>
                                    </template>
                                    <span
                                      >An integer between 1-100, where 1 is the lowest
                                      brightness and 100 is the highest. Adjust
                                      accordingly for desired illumination level.</span
                                    >
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <!-- Data reporting cycle -->
                          <v-row>
                            <v-col cols="12" md="4" class="text-right pt-5">
                              <v-label for="data-reporting-cycle">Data reporting cycle:</v-label>
                            </v-col>
                            <v-col cols="12" md="8">
                              <v-text-field
                                outlined
                                dense
                                :rules="dataReportIntervalRule"
                                type="number"
                                label="Data reporting cycle"
                                min="1"
                                v-model="dataReportInterval"
                                id="data-reporting-cycle"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" class="text-center">
                              <span class="text-sm small-highlight--text">
                                Data reporting cycle:3 means that each report 3 data
                              </span>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>

                      <!-- Automatic Identification -->
                      <v-list-item @click="toggleItem('automatic-identification')">
                        <v-list-item-content>
                          <v-list-item-title :class="{ active: activeItem === 'automatic-identification' }">Automatic Identification</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon
                          v-if="activeItem === 'automatic-identification'"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-else>
                          <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item-content
                        v-show="activeItem === 'automatic-identification'"
                      >
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="12" v-if="!isImageSelected">
                              <v-dialog v-model="showPopup" max-width="60%">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" color="primary"
                                    >Select Image</v-btn
                                  >
                                </template>
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">Select an Image</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-container>
                                      <v-row>
                                        <!-- Dynamically populate image options using v-for -->
                                        <v-col
                                          v-for="(image, index) in deviceImages"
                                          :key="index"
                                          cols="4"
                                        >
                                          <v-img
                                            :src="basePath + image.path"
                                            :aspect-ratio="1"
                                            :contain="true"
                                            @click="selectImage(index)"
                                          ></v-img>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closePopup"
                                      >Close</v-btn
                                    >
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                            <v-col cols="12" v-if="isImageSelected">
                              <v-btn @click="backToImageSelection"
                                ><v-icon>mdi-arrow-left</v-icon></v-btn
                              >
                            </v-col>
                          </v-row>

                          <v-row v-if="isImageSelected">
                            <!-- Image Preview -->
                            <v-col cols="12" class="text-center headline">
                              Selected Image Preview
                              <!-- <v-img :src="basePath+selectedImage" :aspect-ratio="1" :contain="true"></v-img> -->
                            </v-col>

                            <!-- Image Details -->
                            <v-col cols="12">
                              <!-- Model selection -->
                              <v-row>
                                <v-col cols="12" md="4" class="text-right pt-5">
                                  <v-label for="image-model-selection">Model selection:</v-label>
                                </v-col>
                                <v-col cols="12" md="8">
                                  <v-select
                                    outlined
                                    dense
                                    :items="modelSection"
                                    item-text="name"
                                    item-value="value"
                                    v-model="selectedModelSection"
                                    id="image-model-selection"
                                  >
                                  </v-select>
                                </v-col>
                              </v-row>
                              <!-- Automatic Identification -->
                              <v-row>
                                <v-col cols="12" md="4" class="text-right">
                                  <v-label>Automatic Identification:</v-label>
                                </v-col>
                                <v-col cols="12" md="8">
                                  {{ automaticIdentification }}
                                </v-col>
                              </v-row>
                              <!-- Short Image -->
                              <v-row>
                                <v-col cols="12" class="text-center"> IMAGE </v-col>
                              </v-row>
                              <!-- Node Type -->
                              <v-row class="align-center">
                                <v-col cols="4" class="text-right">
                                  <v-label for="node-type">Node type:</v-label>
                                </v-col>
                                <v-col cols="8">
                                  <v-col cols="12" md="8"> {{ nodeType }} </v-col>
                                </v-col>
                              </v-row>
                              <!-- Image Angle -->
                              <v-row class="align-center">
                                <v-col cols="12" md="4" class="text-right">
                                  <v-label for="image-angle">Angle:</v-label>
                                </v-col>
                                <v-col cols="12" md="8">
                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Angle"
                                    min="1"
                                    id="image-angle"
                                    v-model="angle"
                                    :disabled="true"
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Number of digits -->
                              <v-row class="align-center">
                                <v-col cols="12" md="4" class="text-right">
                                  <v-label for="number-of-digits">Number of digits:</v-label>
                                </v-col>
                                <v-col cols="12" md="8">
                                  <!-- <v-text-field outlined dense :rules="numberOfDigitRule" type="number"
                                    label="Number of digits" min="1" v-model="numberOfDigit">
                                  </v-text-field> -->
                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Number of digits"
                                    min="1"
                                    id="number-of-digits"
                                    v-model="numberOfDigit"
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Number of decimal place -->
                              <v-row class="align-center">
                                <v-col cols="12" md="4" class="text-right">
                                  <v-label for="number-of-decimal-place">Number of decimal place:</v-label>
                                </v-col>
                                <v-col cols="12" md="8">
                                  <!-- <v-text-field outlined dense :rules="numberOfDecimalPlaceRule" type="number"
                                    label="Number of decimal place" min="1" v-model="numberOfDecimalPlace">
                                  </v-text-field> -->
                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Number of decimal place"
                                    min="1"
                                    id="number-of-decimal-place"
                                    v-model="numberOfDecimalPlace"
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <!-- Recognition result -->
                              <v-row>
                                <v-col cols="12" md="4" class="text-right pt-6">
                                  <v-label>Recognition result:</v-label>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Recognition result"
                                    min="1"
                                    v-model="recognitionResult"
                                    :disabled="true"
                                    hide-details
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-btn elevation="0" color="primary">Identify</v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn small text @click="tab = 0">Cancel</v-btn>
                    <v-btn small elevation="0" color="primary" @click="configDevice"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
                <!-- Public library container begins -->
                <v-card v-if="item === 'Image Library'" class="px-10" flat>
                  <v-container grid-list-md v-if="!getPublicLibraryLoading">
                    <v-layout row wrap>
                      <v-row>
                        <v-col
                          v-for="publicLib in publicLibraryPics"
                          :key="publicLib.id"
                          cols="12"
                          md="6"
                          lg="4"
                        >
                          <v-card>
                            <v-tooltip :nudge-top="20" allow-overflow top color="#F3F8FF">
                              <template v-slot:activator="{ on, attrs }">
                                <v-img
                                  :src="getImageAbsUrl(publicLib.path)"
                                  aspect-ratio="1"
                                  contain
                                  max-height="100"
                                  min-height="100"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                </v-img>
                              </template>
                              <div style="padding: 5px">
                                <v-img
                                  :src="getImageAbsUrl(publicLib.path)"
                                  contain
                                  aspect-ratio="1"
                                  max-height="350"
                                  min-height="100"
                                  height="350"
                                  width="350"
                                >
                                </v-img>
                              </div>
                            </v-tooltip>
                            <v-card-title>{{ publicLib.value }}</v-card-title>
                            <div
                              class="d-flex flex-column mb-6 bg-surface-variant align-center"
                            >
                              <v-sheet class="mb-2">
                                <v-icon small>mdi-calendar</v-icon>
                                {{ getDate(publicLib.createTime) }}
                              </v-sheet>
                              <v-sheet class="mb-2">
                                <v-icon small>mdi-clock</v-icon>
                                {{ getTime(publicLib.createTime) }}
                              </v-sheet>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-container>
                  <v-card-text v-else>
                    <v-progress-circular
                      indeterminate
                      selectable
                      color="primary"
                    ></v-progress-circular>
                  </v-card-text>
                </v-card>
                <!-- Public library container ends -->

                <!-- Alarm threshold container begins -->
                <v-card v-if="item === 'Alarm threshold'" class="px-10" flat>
                  <v-card-text class="px-0">
                    <div>
                      <v-row>
                        <v-col cols="12" md="12">
                          <span class="text-sm small-highlight--text"
                            >Reminder:exceeding this value will trigger an abnormal
                            alarm</span
                          >
                        </v-col>
                        <v-col cols="12" md="12" class="text-center">
                          <v-switch
                            v-model="isAlarmThresholdOff"
                            label="Threshold switch"
                            class="d-flex align-center"
                            @change="handleOnOffAlarmThreshold(isAlarmThresholdOff)"
                          >
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row v-if="isAlarmThresholdOff" class="align-center">
                        <v-col cols="12" md="6" class="text-right">
                          <v-label for="increase-amount-threshold">Increase amount threshold:</v-label>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            dense
                            persistent-hint
                            type="number"
                            label="Every"
                            for="increase-amount-threshold"
                            min="1"
                            v-model="maxCurrectValue"
                            hide-details
                          >
                            <template v-slot:append-outer>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on">mdi-information</v-icon>
                                </template>
                                <span
                                  >If the current value - the last value is greater than
                                  the set value, it will trigger an alarm for increasing
                                  the amount exceeding the threshold.</span
                                >
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="isAlarmThresholdOff" class="align-center">
                        <v-col cols="12" md="6" class="text-right">
                          <v-label for="decrease-threshold">Decrease Threshold:</v-label>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            dense
                            persistent-hint
                            type="number"
                            label="Every"
                            min="1"
                            v-model="minCurrectValue"
                            id="decrease-threshold"
                            hide-details
                          >
                            <template v-slot:append-outer>
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on">mdi-information</v-icon>
                                </template>
                                <span
                                  >The last value - the current value is greater than the
                                  set value, it will trigger a reduction amount exceeding
                                  the threshold alarm</span
                                >
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn small text @click="tab = 3">Cancel</v-btn>
                    <v-btn small elevation="0" color="primary" @click="configDeviceAlarm"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
                <!-- Alarm threshold container ends -->
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <deviceInnerChart />
    </v-row>
  </v-container>
</template>

<script>
import deviceInnerChart from "@/components/DeviceInnerChart";
import ExportDialog from "@/components/ExoprtDialog";
// import LineChart from '@/components/LineChart'
import moment from "moment";
import { mapActions, mapMutations, mapGetters } from "vuex";
// import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Menu from "@/components/Menu";

export default {
  name: "DeviceInner",
  components: {
    ExportDialog,
    // LineChart,
    deviceInnerChart,
    Menu,
  },
  data: () => {
    return {
      basePath: process.env.VUE_APP_BASE_PATH,
      baseUrl: process.env.VUE_APP_BASE_URL,
      editableReading: null,
      deviceParam: "",
      configMode: "",
      recognizeMode: "",
      deviceDetails: "",

      interval: 1,
      // intervalRule: [
      //   v => (v >= 1 && v <= 28) || 'Range value：[1,28]',
      // ],

      selectedInterval: 1,
      selectedColorChannel: 1,

      showPopup: false,
      deviceImages: [],
      selectedImage: null,
      isImageSelected: false,
      automaticIdentification: null,

      hours: "",
      minutes: "",

      meterNumber: "",
      deviceName: "",
      description: "",
      houseNumber: "",
      deviceStatus: "",
      signal: "",
      productKey: "",
      deviceTypeCode: "",
      needPic: false,
      rotate: false,
      picQuality: 1,
      pictureQualityRule: [(v) => (v >= 1 && v <= 5) || "[1, 5]"],
      flashHeight: 1,
      flashHeightRule: [(v) => (v >= 1 && v <= 100) || "[1, 100]"],
      dataReportInterval: 1,
      dataReportIntervalRule: [(v) => (v >= 1 && v <= 50) || "[1, 50]"],

      isAlarmThresholdOff: false,
      maxCurrectValue: 0,
      minCurrectValue: 0,

      isValidated: false,

      activeItem: "wake-up-configuration",

      options: {},
      date: [],
      menu: false,
      tab: null,
      items: ["Read Data", "Device Configuration", "Image Library", "Alarm threshold"],

      selected: [],
      //   singleSelect: '',
      headers: [
        { text: "Data", value: "data", sortable: false, align: "center" },
        {
          text: "Increment",
          value: "cloudIncrement",
          sortable: false,
          align: "center",
        },
        { text: "Time", value: "createTime", sortable: false, align: "center" },
        {
          text: "Interval (hr)",
          value: "electricity",
          sortable: false,
          align: "center",
        },
        {
          text: "Image",
          value: "path",
          sortable: false,
          align: "center",
          width: "150px",
        },
      ],

      wakeUpInterval: 2,

      wakeUpItems: [
        {
          name: "Wake-up at intervals",
          value: 2,
        },
        {
          name: "Wake-up at daily",
          value: 1,
        },
      ],

      selectedModelSection: "V3",

      modelSection: [
        {
          name: "LD-v1",
          value: "V1",
        },
        {
          name: "LD-v2",
          value: "V2",
        },
        {
          name: "SNAPI-v1.1",
          value: "V3",
        },
      ],

      selectedPicMode: 1,

      picMode: [
        {
          name: "local",
          value: 2,
        },
        {
          name: "Whole picture",
          value: 1,
        },
      ],

      nodeType: null,
      nodeTypeItems: [
        {
          name: "Electricity meter",
          subName: "300 - Electricity meter",
          value: 1,
        },
        {
          name: "LCD electricity meter",
          subName: "33025 - LCD electricity meter",
          value: 2,
        },
      ],

      angle: null,
      numberOfDigit: null,
      // numberOfDigitRule: [
      //   v => v >= 1 || '* Required'
      // ],
      numberOfDecimalPlace: null,
      // numberOfDecimalPlaceRule: [
      //   v => v >= 1 || '* Required'
      // ],
      recognitionResult: null,

      historySeries: {},
    };
  },

  computed: {
    ...mapGetters([
      "sidebarLoading",
      "getHistoryLoading",
      "getHistoryList",
      "getTotalRecords",
      "getTimezone",
      "getAccessToken",
      "getDeviceDetails",
      "getPublicLibraryLoading",
      "getPublicLibraryList",
      "getDeviceMigrationOptions",
      "canMigrateDevice",
    ]),

    intervalRule() {
      if (this.selectedInterval === 1440) {
        return [(v) => (v >= 1 && v <= 28) || "Range value: [1, 28]"];
      } else if (this.selectedInterval === 60) {
        return [(v) => (v >= 1 && v <= 672) || "Range value: [1, 672]"];
      } else {
        // Default rule for day or any other selection
        return [(v) => (v >= 1 && v <= 1) || "Range value: [1, 40320]"];
      }
    },

    timeIntervals() {
      if (this.interval <= 1) {
        return [
          {
            name: "day",
            value: 24 * 60,
          },
          {
            name: "hour",
            value: 60,
          },
          {
            name: "minute",
            value: 1,
          },
        ];
      }
      return [
        {
          name: "days",
          value: 24 * 60,
        },
        {
          name: "hours",
          value: 60,
        },
        {
          name: "minutes",
          value: 1,
        },
      ];
    },

    colorChannel() {
      if (this.selectedColorChannel <= 1) {
        return [
          {
            name: "Binarized or bmp graph",
            value: 1,
          },
          {
            name: "Grayscale",
            value: 2,
          },
          {
            name: "Color image (default)",
            value: 3,
          },
        ];
      }
      return [
        {
          name: "Binarized or bmp graph",
          value: 1,
        },
        {
          name: "Grayscale",
          value: 2,
        },
        {
          name: "Color image (default)",
          value: 3,
        },
      ];
    },

    intervalForCall() {
      if (this.wakeUpInterval == 2) {
        return this.selectedInterval * this.interval;
      }
      return "";
    },

    deviceHistory() {
      return this.getHistoryList?.list;
    },
    // getFullHistory() {
    //   return this.getHistoryList
    // },
    dateRangeText() {
      return this.date.join(" - ");
    },

    publicLibraryPics() {
      return this.getPublicLibraryList?.list || [];
    },
  },

  watch: {
    hours(newVal) {
      // Ensure the value is within 0 and 23
      this.hours = Math.min(Math.max(parseInt(newVal, 10) || 0, 0), 23)
        .toString()
        .padStart(2, "0");
    },

    /* deviceDetails(newVal) {
        console.log('deviceDetailsChanged', newVal);
        this.isAlarmThresholdOff = newVal.maxCorrectValue === -1 || newVal.minCorrectValue === -1 ? false : true;
        this.maxCurrectValue = newVal.maxCorrectValue;
        this.minCurrectValue = newVal.minCorrectValue;
      }, */

    minutes(newVal) {
      // Ensure the value is within 0 and 59
      this.minutes = Math.min(Math.max(parseInt(newVal, 10) || 0, 0), 59)
        .toString()
        .padStart(2, "0");
    },

    $route(newVal) {
      this.getHistory();
      this.fetchPublicLibrary(this.tab);
      if (newVal) {
        this.date = [];
        setTimeout(() => {
          this.getAwakeConfig(newVal.query.productKey, newVal.query.deviceName);
          this.itemData();
        }, 300);
      }
    },

    options: {
      handler() {
        this.getHistory();
      },
      deep: true,
    },

    deviceHistory() {
      this.fillChart();
    },

    tab(activeTabIndex) {
      this.fetchPublicLibrary(activeTabIndex);
    },
  },

  methods: {
    ...mapMutations(["addDeviceNewData"]),
    ...mapActions([
      "getHistoryData",
      "updateDeviceMeterNumber",
      "updateDeviceDescription",
      "updateDeviceHouseNumber",
      "migrateDevice",
      "updateDeviceReading",
    ]),

    backToImageSelection() {
      this.isImageSelected = false;
    },

    toggleItem(itemId) {
      this.activeItem = this.activeItem === itemId ? null : itemId;
    },

    selectImage(imageId) {
      const currentImageObj = this.deviceImages[imageId];
      this.selectedImage = imageId;

      this.getDeviceImageConfiguration(
        this.deviceName,
        this.recognizeMode,
        `${this.basePath}${currentImageObj.path}`,
        currentImageObj.meterId
      );

      this.isImageSelected = true;
      this.closePopup();
    },

    closePopup() {
      this.showPopup = false;
    },

    updateItem(item, field, event) {
      // Update the property directly using the item
      item.data[field] = event.target.textContent;
      this.updateDeviceReading({
        requestId: item.requestId,
        number: event.target.textContent,
        deviceName: this.deviceName,
        timeStamp:
          moment(item.createTime)
            .utcOffset(this.getTimezone * 60)
            .valueOf() / 1000,
        // "2024-01-29 22:21:06"
      });
    },

    stopEditing() {
      this.editableReading = null;
    },

    cancelEditing() {
      this.editableReading = null;
      // fetch the original value from a backup or API call
    },
    startEditing(reqId) {
      this.editableReading = reqId;
    },

    fetchPublicLibrary(activeTabIndex) {
      if (this.items[activeTabIndex] === "Image Library") {
        this.$store.dispatch("getPublicLibrary", {
          productKey: this.$route.query.productKey,
          deviceName: this.$route.query.deviceName,
          page: 1,
          itemsPerPage: 12,
        });
      }
    },

    handleDeviceAlarmThreshold(newValue, isSave = false) {
      const newMaxValue =
        newValue === 1 ? -1 : this.maxCurrectValue !== -1 ? this.maxCurrectValue : 500;
      const newMinValue =
        newValue === 1 ? -1 : this.minCurrectValue !== -1 ? this.minCurrectValue : 10;

      if (isSave) {
        this.maxCurrectValue = newMaxValue < 0 ? newMaxValue : this.maxCurrectValue;
        this.minCurrectValue = newMinValue < 0 ? newMinValue : this.minCurrectValue;
      }

      // const newMaxValue = -1;
      // const newMinValue = -1;
      // console.log('after', newMaxValue, newMinValue);
      // Update Min Correct Value
      const updateMinCurrectValue = new FormData();
      updateMinCurrectValue.append("deviceNameList", JSON.stringify([this.deviceName]));
      updateMinCurrectValue.append("minCorrectValue", newMinValue);
      updateMinCurrectValue.append("access_token", this.getAccessToken);

      axios
        .put(
          `${this.baseUrl}/manage/device/updateMinCorrectValueList`,
          updateMinCurrectValue
        )
        .then((response) => response.data.data)
        .then(() => {
          // Update Max Correct Value
          const updateMaxCurrectValue = new FormData();
          updateMaxCurrectValue.append("deviceName", this.deviceName);
          updateMaxCurrectValue.append("maxCorrectValue", newMaxValue);
          updateMaxCurrectValue.append("access_token", this.getAccessToken);

          axios
            .put(
              `${this.baseUrl}/manage/device/updateMaxCorrectValue`,
              updateMaxCurrectValue
            )
            .then((response) => response.data.data)
            .then(() => {
              this.getDeviceConfiguration();

              // this.isAlarmThresholdOff = newValue === 1 ? false : true;
              // this.maxCurrectValue = newMaxValue;
              // this.minCurrectValue = newMinValue;
            });
        });
    },

    handleOnOffAlarmThreshold(newValue) {
      const updatedAlarm = newValue === false ? 1 : 0;
      console.log("handleOnOffAlarmThreshold", updatedAlarm);
      // wake config update
      const wakeConfigAlarmFormData = new FormData();
      wakeConfigAlarmFormData.append("deviceName", this.deviceName);
      wakeConfigAlarmFormData.append("isOff", updatedAlarm);
      wakeConfigAlarmFormData.append("access_token", this.getAccessToken);

      axios
        .put(`${this.baseUrl}/manage/device/threshold/onOff`, wakeConfigAlarmFormData)
        .then((response) => response.data.data);

      this.handleDeviceAlarmThreshold(updatedAlarm);
    },

    handleUpdateDeviceMeterNumber() {
      this.updateDeviceMeterNumber({
        meterNumber: this.$refs.editableMeterNumber.innerText,
        deviceName: this.deviceName,
        productKey: this.productKey,
        deviceTypeCode: this.deviceTypeCode,
      });
    },

    handleUpdateDeviceDescription() {
      this.updateDeviceDescription({
        houseNumber: this.$refs.editableDeviceHouseNumber.innerText,
        description: this.$refs.editableDeviceDescripion.innerText,
        deviceName: this.deviceName,
        productKey: this.productKey,
        deviceTypeCode: this.deviceTypeCode,
      });
    },

    handleUpdateDeviceHouseNumber() {
      this.updateDeviceHouseNumber({
        houseNumber: this.$refs.editableDeviceHouseNumber.innerText,
        description: this.$refs.editableDeviceDescripion.innerText,
        deviceName: this.deviceName,
        productKey: this.productKey,
        deviceTypeCode: this.deviceTypeCode,
      });
    },

    itemData() {
      const deviceData = this.getDeviceDetails(
        this.$route.query.applicationId,
        this.$route.query.productKey,
        this.$route.query.deviceName
      );

      if (!deviceData) {
        return {};
      }
      this.meterNumber = deviceData.meterNumber;
      this.deviceName = deviceData.deviceName;
      this.description = deviceData.description;
      this.houseNumber = deviceData.houseNumber;
      this.deviceStatus = deviceData.deviceStatus;
      this.signal = deviceData.signal;
      this.productKey = deviceData.productKey;
      this.deviceTypeCode = deviceData.deviceTypeCode;

      // this.isAlarmThresholdOff = (deviceData.maxCorrectValue === -1 || deviceData.minCorrectValue === -1) ? false : true;
      // this.setAlarmThreshold(deviceData);
      this.nodeTypeItems.map((node) => {
        if (node.value === deviceData.useCode) {
          this.nodeType = node.subName;
        }
      });
      // console.log('this.nodeTypeItems', this.nodeTypeItems);

      this.deviceDetails = deviceData;
      return deviceData;
    },

    configDevice() {
      // form validation
      if (!this.$refs.deviceConfigurationFrom[0].validate()) return;

      // wake config update
      const wakeConfigFormData = new FormData();
      wakeConfigFormData.append("productKey", this.$route.query.productKey);
      wakeConfigFormData.append("deviceName", this.deviceName);
      wakeConfigFormData.append("mode", this.wakeUpInterval);
      wakeConfigFormData.append(
        "interval",
        this.wakeUpInterval === 2 ? this.intervalForCall : 1440
      );
      wakeConfigFormData.append("hour", this.wakeUpInterval === 1 ? this.hours : " ");
      wakeConfigFormData.append("minute", this.wakeUpInterval === 1 ? this.minutes : " ");
      wakeConfigFormData.append("needPic", this.needPic);
      wakeConfigFormData.append("colorChannel", this.selectedColorChannel);
      wakeConfigFormData.append("access_token", this.getAccessToken);
      wakeConfigFormData.append("timeZone", 1);

      axios
        .put(`${this.baseUrl}/manage/wakeConfig`, wakeConfigFormData)
        .then((response) => response.data.data);

      const deviceSetFormData = new FormData();
      deviceSetFormData.append("rotate", this.rotate);
      deviceSetFormData.append("picQuality", this.picQuality);
      deviceSetFormData.append("productKey", this.$route.query.productKey);
      deviceSetFormData.append("deviceName", this.deviceName);
      deviceSetFormData.append("configMode", this.configMode);
      deviceSetFormData.append("picMode", this.selectedPicMode);
      deviceSetFormData.append("flashHeight", this.flashHeight);
      deviceSetFormData.append("dataReportInterval", this.dataReportInterval);
      deviceSetFormData.append("recognizeMode", this.recognizeMode);
      deviceSetFormData.append(
        "deviceParam",
        JSON.stringify([
          {
            name: this.deviceParam.name,
            type: this.deviceParam.type,
            value: this.selectedModelSection,
            desc: this.deviceParam.desc,
          },
        ])
      );
      deviceSetFormData.append("access_token", this.getAccessToken);

      // device set update
      axios
        .put(`${this.baseUrl}/manage/deviceSet`, deviceSetFormData)
        .then((response) => response.data.data);
    },

    configDeviceAlarm() {
      const newVal = this.isAlarmThresholdOff === false ? 1 : 0;
      this.handleDeviceAlarmThreshold(newVal, true);
    },

    getColor(item) {
      if (item === "Reading") {
        return "#F4FDF1";
      }
      if (item === "Sleep") {
        return "#F4F7FE";
      }
      if (item === "Offline") {
        return "#F8E7ED";
      }
    },

    getHistory() {
      let obj = {
        productKey: this.$route.query.productKey,
        deviceName: this.$route.query.deviceName,
        startTime: this.date[0]
          ? Date.parse(this.date[0].split("-").join("/") + ", " + "12:00:00 AM") / 1000
          : Date.parse("10/20/2018, 12:00:00 AM") / 1000,
        endTime: this.date[1]
          ? Date.parse(this.date[1].split("-").join("/") + ", " + "11:59:59 PM") / 1000
          : parseInt((Date.now() / 1000).toFixed()),
        itemsPerPage: this.options ? this.options.itemsPerPage : 10,
        page: this.options ? this.options.page : 1,
      };
      this.getHistoryData(obj);
    },

    fillChart() {
      // this.historySeries.labels = []
      // this.historySeries.datasets[0].data = []
      let data = {};
      data.x = [];
      data.y = [];

      this.deviceHistory?.map((item) => {
        data.x.push(item.createTime);
        data.y.push(item.data.number);
      });
      this.historySeries = data;
    },

    saveHistory(date) {
      this.$refs.menu[0].save(date);
      this.getHistory();
    },

    getDate(item) {
      return moment(item)
        .utcOffset(this.getTimezone * 60)
        .format("DD-MM-YYYY");
    },

    getPeriod(item) {
      let index = this.deviceHistory.indexOf(item);
      // if (index  1 >= 0) {
      if (this.deviceHistory[index + 1]) {
        let date1 = moment(this.convertTime(item.createTime), "DD-MM-YYYY HH:mm:ss");
        let date2 = moment(
          this.convertTime(this.deviceHistory[index + 1].createTime),
          "DD-MM-YYYY HH:mm:ss"
        );

        let difference = date1.diff(date2, "hours");

        return difference;
      }

      return "0";
    },

    convertTime(item) {
      return moment(item)
        .utcOffset(this.getTimezone * 60)
        .format("DD-MM-YYYY HH:mm:ss");
    },

    getTime(item) {
      return moment(item)
        .utcOffset(this.getTimezone * 60)
        .format("HH:mm:ss");
    },

    getAwakeConfig(prodkey, deviceName) {
      axios
        .get(
          `${this.baseUrl}/manage/wakeConfig/keyAndName?productKey=${prodkey}&deviceName=${deviceName}&access_token=${this.getAccessToken}`
        )
        .then((response) => response.data.data)
        .then((res) => {
          this.wakeUpInterval = res.mode;
          this.interval = res.interval;

          if (this.interval >= 60) {
            this.selectedInterval = 60;
            this.interval = (res.interval / 60).toFixed(0);
          }
          if (this.interval >= 1440) {
            this.selectedInterval = 24 * 60;
            this.interval = (res.interval / (24 * 60)).toFixed(0);
          }
          this.hours = res.hour;
          this.minutes = res.minute;
        });
    },

    getDeviceConfigurationDetails(deviceName) {
      const keys = ["autoRecognition", "model", "fixedNumber"];
      const encodedKeys = encodeURIComponent(JSON.stringify(keys));
      axios
        .get(
          `${this.baseUrl}/manage/deviceSet/deviceName?deviceName=${deviceName}&keys=${encodedKeys}&access_token=${this.getAccessToken}`
        )
        .then((response) => response.data.data)
        .then((res) => {
          // Wake-up Configuration Settings
          this.needPic = res.needPic;
          this.rotate = res.rotate;
          this.selectedColorChannel = res.colorChannel;

          // Equipment details Settings
          this.picQuality = res.picQuality;
          this.selectedPicMode = res.picMode === 0 ? 1 : res.picMode;
          this.flashHeight = res.flashHeight;
          this.dataReportInterval = res.dataReportInterval;

          this.selectedModelSection = res.extraParam[0].value.value;

          this.deviceParam = res.extraParam[0].value;
          this.configMode = res.configMode;
          this.recognizeMode = res.recognizeMode;
        });
    },

    getDeviceImagesDetails(deviceName) {
      axios
        .get(`${this.baseUrl}/manage/picture/lastedPicture`, {
          params: {
            currentPage: 1,
            pageSize: 30,
            deviceName: deviceName,
            picMode: 2,
            productKey: this.$route.query.productKey,
            access_token: this.getAccessToken,
          },
        })
        .then((response) => response.data.data)
        .then((res) => {
          this.deviceImages = res.list;
        });
    },

    getDeviceImageConfiguration(deviceName, recognizeMode, imagePath, meterId) {
      axios
        .post(
          `${this.basePath}/image_config/config`,
          {
            source: 'self',
            is_config: 0,
            image_path: imagePath,
            meter_id: meterId,
            imageType: 1,
            recognizeMode: recognizeMode,
            deviceName: deviceName,
            find_box_web: 0,
          },
          {
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `Basic ${this.getAccessToken}`,
              Origin: 'http://api.snapi.com.au/'
            },
            withCredentials: true,
          }
        )
        .then((res) => {
          console.log("getDeviceImageConfiguration", res);
        });
      /* // Create headers object
        const myHeaders = { 'Content-Type': 'application/json' };

        // Create data object
        const data = {
          deviceName: '866207059715973',
          find_box_web: '0',
          imageType: 1,
          image_path: 'http://10.1.2.10/pic/XjZdZydWQAy/866207059715973/2024/4/18/1713416555.jpg',
          is_config: 0,
          meter_id: 33025,
          recognizeMode: 7,
          source: 'self'
        };

        // Create Axios POST request
        axios.post('http://20.53.98.203/image_config/config', data, { headers: myHeaders })
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.error(error);
          }); */
    },

    setAlarmThreshold(deviceDitails) {
      if (deviceDitails) {
        this.isAlarmThresholdOff =
          deviceDitails.maxCorrectValue === -1 || deviceDitails.minCorrectValue === -1
            ? false
            : true;
        this.maxCurrectValue = deviceDitails.maxCorrectValue;
        this.minCurrectValue = deviceDitails.minCorrectValue;
      }
    },

    getImageAbsUrl(imgPath) {
      return `${this.baseUrl.replace("/gateway", "")}${imgPath}`;
    },

    onMenuItemClick(item) {
      if (item.value) {
        this.$store
          .dispatch("migrateDevice", {
            toProject: item.value,
            fromProject: this.$route.query.productKey,
            deviceName: this.deviceName,
          })
          .then((toProject) => {
            if (toProject) {
              this.$router.push({
                name: "DeviceInner",
                params: { id: this.$route.params.id },
                query: {
                  applicationId: this.$route.query.applicationId,
                  deviceName: this.deviceName,
                  productKey: toProject,
                },
              });
            }
          });
      }
    },

    getDeviceConfiguration() {
      axios
        .get(
          `${this.baseUrl}/manage/v3/device?currentPage=1&pageSize=10&productKey=${this.$route.query.productKey}&access_token=${this.getAccessToken}`
        )
        .then((res) => res.data.data)
        .then((res) => {
          const deviceFilterList = res.list.filter((item) => {
            if (item.id == this.deviceDetails.id) {
              this.maxCurrectValue = item.maxCorrectValue;
              this.minCurrectValue = item.minCorrectValue;
              console.log("getDeviceConfiguration", item);
              this.isAlarmThresholdOff =
                item.maxCorrectValue === -1 || item.minCorrectValue === -1 ? false : true;
            }

            return item.id == this.deviceDetails.id;
          });
          this.setAlarmThreshold(deviceFilterList[0]);
          // console.log('getDeviceConfiguration call', deviceFilterList);
          this.$store.commit("addDeviceNewData", deviceFilterList[0]);
        });
    },
  },

  mounted() {
    this.getHistory();
    this.getAwakeConfig(this.$route.query.productKey, this.$route.query.deviceName);
    this.getDeviceConfigurationDetails(this.$route.query.deviceName);
    this.getDeviceImagesDetails(this.$route.query.deviceName);
    this.getDeviceConfiguration();

    // Add event listener to close popup when user clicks outside of it
    document.addEventListener("click", this.handleDocumentClick);
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
  border-radius: 0 !important;
}

::v-deep .v-tab {
  letter-spacing: 0.03rem;
}
</style>

<style lang="scss">
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.v-slide-group__content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  bottom: 0;
}

.small-highlight--text {
  color: #d2691e !important;
  /* font-size: 12px !important; */
  padding: 20px 5px;
}

.v-list-item__title.active {
  color: #477ff6; /* Your desired color */
}
</style>
